
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/ComponentsSystem/IQC/List.vue";
import axios from "axios";

export default defineComponent({
  name: "projects",
  components: {
    List,
  },
  data(): {
    DateType: string;
    startDate: string;
    endDate: string;
    Step: number;
  } {
    return {
      DateType: "",
      startDate: "",
      endDate: "",
      Step: 1,
    };
  },
  methods: {
    async Init() {
      // axios
      //   .post("https://ah.artinuro.com:8443/Model/List", {
      //     Paging: {
      //       pageSize: 10,
      //     },
      //     Search: {},
      //   })
      //   .then(res => {
      //     console.log(res);
      //     this.List = res.data;
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },
  },
  created() {
    this.Init();
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("進料檢驗列表", ["進料檢驗管理"]);
    });
  },
});
