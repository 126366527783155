
import { defineComponent } from "vue";
// import List from "@/data/Ht-Tr-parameter/List.json";
import axios from "axios";
import moment from "moment";
// import List1 from "@/data/IQCDetail.json";
import IQCList from "@/data/IQC/List.json";

export default defineComponent({
  name: "kt-widget-12",
  components: {},
  props: {
    widgetClasses: String,
  },
  data(): {
    Auth: any;
    Name: any;
    ApiURL: string;
    Param: any;
    startDate: string;
    endDate: string;
    Step: number;
    ModelList: Array<any>;
    List: Array<any>;
    ListHeader: any;
    SpecList: Array<any>;
    Model_Id: any;
    SpecItem: any;
    SpecType: any;
    IQCItem: any;
    IQCType: any;
    IQCDetailList: Array<any>;
    IQCDetail: any;
    Process: any;
    Inspection_No: any;
    List1: Array<any[any]>;
    keywords: any;
    IQCList: Array<any>;
    ResultList: Array<any>;
  } {
    return {
      Auth: {},
      Name: "",
      ApiURL: "https://ah.artinuro.com:8443/",
      Param: {
        Paging: {
          pageSize: 10,
          pageNumber: 1,
        },
        Search: {},
      },
      startDate: "",
      endDate: "",
      Step: 1,
      ModelList: [],
      List: [],
      ListHeader: {},
      SpecList: [],
      Model_Id: {},
      SpecItem: {},
      SpecType: 0,
      IQCItem: {},
      IQCType: 0,
      IQCDetailList: [],
      IQCDetail: {},
      Process: "",
      Inspection_No: 1,
      List1: [],
      keywords: "",
      IQCList: [],
      ResultList: [
        {
          Id: "49e232ff-206c-c7b1-8f8a-78d0d14aa2a7",
          Model_Id: "475c3b73-84df-48fc-9749-57b98895d9a2",
          Model_No: "10B-E6151-00",
          Inspection_Date: "2024-01-24T15:57:19.804Z",
          Deliever_Date: null,
          Manufacturer_Name: "上翊",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813147",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "7e37b325-09a6-c912-94ce-07570784ace5",
          Model_Id: "884a65cb-14d7-4420-84b1-5a986eee31a8",
          Model_No: "ANT-40452-00",
          Inspection_Date: "2024-02-09T08:58:27.718Z",
          Deliever_Date: null,
          Manufacturer_Name: "升大",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813160",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "adc1abf4-28cc-559c-5934-558ef9819aeb",
          Model_Id: "1992ccf4-7c16-4b2e-9989-6a679e21a61a",
          Model_No: "RT642-065-20",
          Inspection_Date: "2024-03-06T22:10:36.973Z",
          Deliever_Date: null,
          Manufacturer_Name: "上翊",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813143",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "ec106a5c-8add-1d48-8cc5-5b7f5b3a31dc",
          Model_Id: "587ba294-b851-4656-9459-b377c3ad6684",
          Model_No: "RT642-131-00",
          Inspection_Date: "2024-03-07T19:59:45.605Z",
          Deliever_Date: null,
          Manufacturer_Name: "上翊",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813141",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "0b4eef9d-fc8b-6620-8458-9df2ae6748f2",
          Model_Id: "a9172de1-8f4b-4020-b732-463d737bef54",
          Model_No: "XOE-46328-0",
          Inspection_Date: "2024-03-14T16:35:39.19Z",
          Deliever_Date: null,
          Manufacturer_Name: "超正",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813168",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "e90652d7-e3a1-80b7-5e0e-f13e4211ba95",
          Model_Id: "ab804fbd-1fe3-4a22-bd0e-5d2316510ad9",
          Model_No: "RT176-1019-0",
          Inspection_Date: "2024-03-22T16:22:29.136Z",
          Deliever_Date: null,
          Manufacturer_Name: "超正",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813162",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "82a76ba5-bb10-4c96-d81e-77727734f1b7",
          Model_Id: "c08c408a-8b2c-49eb-b242-26965e4a2e32",
          Model_No: "11211-JR2-00",
          Inspection_Date: "2024-03-28T13:29:49.578Z",
          Deliever_Date: null,
          Manufacturer_Name: "升大",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813152",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "5b08ba93-3b7c-3d54-8eba-3adc679efe8b",
          Model_Id: "4661a993-e9a0-4567-b926-c9e06b026cb4",
          Model_No: "RT172-1564-0",
          Inspection_Date: "2024-03-29T03:45:16.677Z",
          Deliever_Date: null,
          Manufacturer_Name: "升大",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813149",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "2c3c1bf8-2bbd-d863-610d-6f4e12096231",
          Model_Id: "d90261c3-b1af-4756-9b13-137f98ad9d00",
          Model_No: "ANT-187012-0",
          Inspection_Date: "2024-03-30T09:17:43.08Z",
          Deliever_Date: null,
          Manufacturer_Name: "超正",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813164",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "a364d7c7-cc77-72d9-7329-4a2cf6170d33",
          Model_Id: "54a701ec-119a-4e99-bd3f-8069fe22f230",
          Model_No: "23432-10A-00",
          Inspection_Date: "2024-04-02T04:05:40.111Z",
          Deliever_Date: null,
          Manufacturer_Name: "升大",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813156",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "24b6a4ad-8788-4f8d-13f0-9136a6cb0814",
          Model_Id: "e42d9806-b745-435d-b23d-cf78bfc2bb65",
          Model_No: "RT494-1151-00",
          Inspection_Date: "2024-04-05T07:08:11.605Z",
          Deliever_Date: null,
          Manufacturer_Name: "超正",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813165",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "c7a127dd-7119-e22a-a3b2-75d7c3a66bbd",
          Model_Id: "74cef1a7-70bc-4167-8b11-c187cd8f5e08",
          Model_No: "RT494-1693-0",
          Inspection_Date: "2024-04-12T20:44:41.378Z",
          Deliever_Date: null,
          Manufacturer_Name: "超正",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813166",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "ee3c8e05-204f-9ca7-a426-4622ef7dddd7",
          Model_Id: "d6e8572a-e25f-4ee2-b559-5093c56ce22b",
          Model_No: "RT664-167-00",
          Inspection_Date: "2024-04-13T11:22:52.804Z",
          Deliever_Date: null,
          Manufacturer_Name: "上翊",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813142",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "5e96e7bf-f22a-f8de-873c-4128e2cb0fa4",
          Model_Id: "ac001581-7184-45d8-96c8-ae3808775f01",
          Model_No: "RT171-1276-0",
          Inspection_Date: "2024-04-20T12:36:39.003Z",
          Deliever_Date: null,
          Manufacturer_Name: "上翊",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813145",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "e0d72473-31bc-cb98-8418-38d58afc4f9f",
          Model_Id: "78b6ad88-c087-4afb-bb8a-9000d719bcfc",
          Model_No: "23432-202-00",
          Inspection_Date: "2024-04-25T13:45:05.968Z",
          Deliever_Date: null,
          Manufacturer_Name: "升大",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813155",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "ff5d355d-dfcf-b24a-5e45-9750b5d15a0d",
          Model_Id: "b4693e5c-e89a-43b6-aba8-1bec0dc255d4",
          Model_No: "ANT-100456-11",
          Inspection_Date: "2024-04-28T11:35:57.191Z",
          Deliever_Date: null,
          Manufacturer_Name: "升大",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813154",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "8d0c27a4-fb7b-e134-5d2a-cf9cb2b422c9",
          Model_Id: "826cc5e5-fcb7-4de1-826d-e1ea45454b5e",
          Model_No: "RT172-1563-0",
          Inspection_Date: "2024-05-09T05:21:50.249Z",
          Deliever_Date: null,
          Manufacturer_Name: "升大",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813158",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "7ace173a-4656-117f-0109-cc78a67c5793",
          Model_Id: "a8dbc73f-21e4-47dc-995f-4f7cef8bcac1",
          Model_No: "RT172-1321-0",
          Inspection_Date: "2024-05-12T17:56:06.824Z",
          Deliever_Date: null,
          Manufacturer_Name: "超正",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813161",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "d5c21231-936f-5bea-3cb2-99e90c1c99f2",
          Model_Id: "3a66dc57-8297-4052-b7ce-a67c6d8c783a",
          Model_No: "ANT-1187011-0",
          Inspection_Date: "2024-05-14T16:30:49.064Z",
          Deliever_Date: null,
          Manufacturer_Name: "升大",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813157",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "48ae7b61-0cd9-645b-7e47-af8403416116",
          Model_Id: "d89ee0e0-7a99-4e93-bee9-97e5d3254772",
          Model_No: "RT172-1320-0",
          Inspection_Date: "2024-05-18T22:42:23.709Z",
          Deliever_Date: null,
          Manufacturer_Name: "上翊",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813144",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "eb128677-90c5-910b-7754-5f7f9d619af6",
          Model_Id: "f1207e3a-4779-42fb-becb-9d41c3a95479",
          Model_No: "RT642-063-20",
          Inspection_Date: "2024-05-25T08:52:58.986Z",
          Deliever_Date: null,
          Manufacturer_Name: "上翊",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813140",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "49d2ac41-8673-92e2-1e62-411ae5d6199f",
          Model_Id: "38fe6825-be7c-4ab6-b615-b8d886428854",
          Model_No: "RT494-1185-0",
          Inspection_Date: "2024-06-07T06:45:15.977Z",
          Deliever_Date: null,
          Manufacturer_Name: "超正",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813167",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "fd80f1bc-9b18-cb94-7f90-401e0d422130",
          Model_Id: "33fbb039-a0ba-41c8-805c-118353993130",
          Model_No: "RT642-139-00",
          Inspection_Date: "2024-06-08T18:41:12.285Z",
          Deliever_Date: null,
          Manufacturer_Name: "升大",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813148",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "1a6464c0-fb66-7265-cb42-5ee3ba9d4a7b",
          Model_Id: "b3567a29-f63b-495f-be2c-1bf08a51c7fb",
          Model_No: "ANT-40362-00",
          Inspection_Date: "2024-06-10T02:58:34.782Z",
          Deliever_Date: null,
          Manufacturer_Name: "升大",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813150",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
        {
          Id: "ba307589-55fd-3d93-e226-619a6b9e676c",
          Model_Id: "1d39e67d-68dd-4d73-9085-ad1690d69f05",
          Model_No: "ANT-187013-0",
          Inspection_Date: "2024-06-20T23:20:38.51Z",
          Deliever_Date: null,
          Manufacturer_Name: "升大",
          Item_Classification: "",
          Sheet_Classification: "",
          Sheet_No: "813159",
          Manufacture_Order: "",
          Process_Name: "研磨",
          Bath: 0,
          Inspection_Quantity: 0,
          Receive_Sonfirmation: true,
          Reject_Sonfirmation: true,
          Inspectors: "",
          Confirm_Staff: "",
          Admit_Staff: "",
          Judgment: true,
          Opinion: "",
          Mark: false,
          Enable: false,
          Removed: false,
          IQCDetails: [],
          Model: null,
        },
      ],
    };
  },
  watch: {
    keywords(newVal) {
      this.Param.Search.keywords = newVal;
      this.GetList(1);
    },
  },
  methods: {
    newGuid() {
      var guid = "";
      for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
      }
      return guid;
    },
    DayFormatter(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    TimeFormatter(value) {
      return moment(value).format("HH:mm");
    },
    DateFormatter(value) {
      return moment(value).format("YYYY-MM-DD  HH:mm");
    },

    async Init() {
      let str: any;
      str = localStorage.getItem("Auth");
      this.Auth = JSON.parse(str);
      this.Name = this.Auth.Name;

      this.GetList(1);
      axios
        .post(this.ApiURL + "Model/List", {
          Paging: {
            pageSize: 999,
            pageNumber: 1,
          },
          Search: {},
        })
        .then(res => {
          this.ModelList = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async GetList(val) {
      this.Param.Paging.pageNumber = val;
      if (this.Name != "Root") {
        this.Param.Search.keywords = encodeURI(this.Name);
      }
      // this.Param.Search.keywords = "123";
      axios
        .post(this.ApiURL + "IQC/List", this.Param)
        .then(res => {
          this.List = res.data;
          this.ListHeader = JSON.parse(res.headers["list-header"]).Paging;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async NewIQC() {
      this.IQCType = 1;
      this.IQCItem = {
        Id: this.newGuid(),
        Model_Id: "",
        Model_No: "",
        Inspection_Date: "",
        Deliever_Date: "",
        Manufacturer_Name: "",
        Item_Classification: "",
        Sheet_Classification: "",
        Sheet_No: "",
        Manufacture_Order: "",
        Process_Name: "",
        Bath: 0,
        Inspection_Quantity: 0,
        Receive_Sonfirmation: true,
        Reject_Sonfirmation: true,
        Inspectors: "",
        Confirm_Staff: "",
        Admit_Staff: "",
        Judgment: true,
        Opinion: "",
      };
      if (this.Name != "Root") {
        this.IQCItem.Manufacturer_Name = this.Name;
      } else {
        this.IQCItem.Manufacturer_Name = "全宏";
      }
      this.Step = 2;
    },

    RandomDate() {
      var max = new Date().getTime();
      var min = new Date(2024, 0, 1).getTime();
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async Delete(item) {
      var yes = confirm("請確定是否刪除");
      if (yes) {
        axios
          .delete(this.ApiURL + "IQC", {
            data: item,
            headers: { Authorization: "***" },
          })
          .then(res => {
            console.log(res);
            alert("刪除成功");
            this.Init();
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    async SaveIQC() {
      switch (this.IQCType) {
        case 0:
          console.log("put");

          break;

        case 1:
          console.log("post");
          axios
            .post(this.ApiURL + "IQC", this.IQCItem)
            .then(res => {
              this.GetDetailList(this.IQCItem);
              this.Init();
              this.Step = 3;
            })
            .catch(err => {
              console.log(err);
            });
          break;
      }
    },
    async GetDetailList(item) {
      this.IQCItem = item;
      await this.GetSpecList(this.IQCItem.Model_Id, this.IQCItem.Process_Name);
      axios
        .post(this.ApiURL + "IQCDetail/List/" + this.IQCItem.Id, {
          Paging: {
            pageSize: 999,
            pageNumber: 1,
          },
          Search: {},
        })
        .then(res => {
          this.IQCDetailList = res.data;
          this.Step = 3;
          for (let i = 0; i < this.IQCDetailList.length; i++) {
            if (this.IQCDetailList[i].Inspection_No >= this.Inspection_No) {
              this.Inspection_No = this.IQCDetailList[i].Inspection_No + 1;
            }
          }
          if (this.IQCDetailList.length == 0) {
            this.Inspection_No = 1;
          }
          if (res.data.length == 0) {
            this.GetDetailList(this.ResultList[this.getRandom(0, 24)]);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async GetNewDetail() {
      this.IQCDetail = {
        Id: this.newGuid(),
        IQC_Id: this.IQCItem.Id,
        ModelSpec_Id: "",
        Measurement: 0,
        Judgment: "",
        Item_No: "",
        Inspection_No: this.Inspection_No,
        SampleNo: 0,
      };
    },
    async GetSpecList(id, Process_Name) {
      this.Model_Id = id;
      axios
        .post(this.ApiURL + "ModelSpec/List/" + id, {
          Paging: {
            pageSize: 999,
            pageNumber: 1,
          },
          Search: {},
        })
        .then(res => {
          this.SpecList = res.data;
          this.SpecList = this.SpecList.filter((item, index) => {
            return item.Process == Process_Name;
          });
          this.Process = Process_Name;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async SaveIQCDetail(type) {
      for (let i = 0; i < this.SpecList.length; i++) {
        await this.GetNewDetail();
        console.log(this.SpecList[i].Measurement);
        if (
          this.SpecList[i].Measurement != "" ||
          this.SpecList[i].Measurement !== undefined
        ) {
          this.IQCDetail.Measurement = this.SpecList[i].Measurement;
          this.IQCDetail.ModelSpec_Id = this.SpecList[i].Id;
          this.IQCDetail.Item_No = this.SpecList[i].Item_No;
          if (this.SpecList[i].Lower == null) {
            this.SpecList[i].Lower = 0;
          }
          if (
            this.SpecList[i].Lower <= this.IQCDetail.Measurement &&
            this.IQCDetail.Measurement <= this.SpecList[i].Upper
          ) {
            this.IQCDetail.Judgment = true;
          } else {
            this.IQCDetail.Judgment = false;
          }
          axios
            .post(this.ApiURL + "IQCDetail", this.IQCDetail)
            .then(res => {
              console.log(res);
            })
            .catch(err => {
              console.log(err);
            });
        }
      }
      setTimeout(() => {
        this.GetDetailList(this.IQCItem);
      }, 600);
    },
    async DeleteIQCDetail(item) {
      var yes = confirm("請確定是否刪除");
      if (yes) {
        axios
          .delete(this.ApiURL + "IQCDetail", {
            data: item,
            headers: { Authorization: "***" },
          })
          .then(res => {
            console.log(res);
            alert("刪除成功");
            this.GetDetailList(this.IQCItem);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    async GetSpec(id) {
      axios
        .get(this.ApiURL + "ModelSpec/" + id)
        .then(res => {
          this.SpecItem = res.data;
          this.SpecType = 0;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async GetNewSpec() {
      this.SpecItem = {
        Id: this.newGuid(),
        Upper: 0,
        Lower: 0,
        Model_Id: this.SpecList[0].Model_Id,
        Item: "",
        Item_No: "",
        Sheet_No: "",
        Importance: "",
        Inspection_Method: "",
        Specification: 0,
        Unit: "",
        Mark: false,
        Enable: false,
        Removed: false,
        Process: "",
        IQCDetails: [],
        Model: null,
      };

      this.SpecType = 1;
    },
    async SaveSpec() {
      // switch (this.SpecType) {
      //   case 0:
      //     console.log("put");
      //     axios
      //       .put(this.ApiURL + "ModelSpec", this.SpecItem)
      //       .then(res => {
      //         console.log(res);
      //         alert("修改成功");
      //         this.GetSpecList(this.SpecItem.Model_Id);
      //       })
      //       .catch(err => {
      //         console.log(err);
      //       });
      //     break;
      //   case 1:
      //     console.log("post");
      //     axios
      //       .post(this.ApiURL + "ModelSpec", this.SpecItem)
      //       .then(res => {
      //         console.log(res);
      //         alert("新增成功");
      //         this.GetSpecList(this.SpecItem.Model_Id);
      //       })
      //       .catch(err => {
      //         console.log(err);
      //       });
      //     break;
      // }
      // switch (type) {
      //   case "put":
      //
      //     break;
      //   case "post":
      //     break;
      // }
    },
    async DeleteSpec(item) {
      // var yes = confirm("請確定是否刪除");
      // if (yes) {
      //   axios
      //     .put(this.ApiURL + "ModelSpec/Removed", item)
      //     .then(res => {
      //       console.log(res);
      //       alert("刪除成功");
      //       this.GetSpecList(this.Model_Id);
      //     })
      //     .catch(err => {
      //       console.log(err);
      //     });
      // }
    },
    getRandom(min, max) {
      const floatRandom = Math.random();
      const difference = max - min;
      const random = Math.round(difference * floatRandom);
      const randomWithinRange = random + min;
      return randomWithinRange;
    },
  },
  created() {
    this.Init();
  },
});
